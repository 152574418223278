/// <reference types="../definitions/index"/>
import Swal from "sweetalert2";
// this way of import should  make ts-node tests work. Probably should not be changed
import { enqueueSnackbar, SnackbarKey } from "notistack";
import * as swal from "sweetalert2";
import { defaultTo, isEmpty } from "lodash";
export const toast = Swal.mixin({
  toast: true,
  position: "top-right",
  showConfirmButton: false,
  timer: 3000,
});

type ToastFunction = (title: string, text?: string) => SnackbarKey;

export interface Toasts {
  success: ToastFunction;
  error: ToastFunction;
  warn: ToastFunction;
  info: ToastFunction;
}

/**
 * Displays a success snackbar notification with the given title and optional text.
 * If no text is provided, only the title will be displayed.
 * @param {string} title - The title of the success message.
 * @param {string} [text] - Optional additional text to display in the success message.
 * @returns {SnackbarKey} - The key of the displayed snackbar.
 */
export function success(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), {
    variant: "success",

    autoHideDuration: 5000,
  });
}

function getSnackText(title: string, text?: string): string {
  let snackBarText = title;
  if (!isEmpty(title) && !isEmpty(text)) {
    snackBarText = title + ": " + text;
  }
  return snackBarText;
}

export function error(
  title: string,
  text?: string,
  showConfirmButton?: boolean,
): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), {
    variant: "error",
    autoHideDuration: showConfirmButton ? null : 5000,
    persist: showConfirmButton,
  });
}

export function warn(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), { variant: "warning" });
}

export function info(title: string, text?: string): SnackbarKey {
  return enqueueSnackbar(getSnackText(title, text), { variant: "info" });
}

export function initializeFlashMessages() {
  $('[data-toggle="flash-message"]').map((index, element) => {
    const flashMessage = $(element);
    const title = flashMessage.attr("data-title");
    const text = flashMessage.attr("data-text");
    const type = flashMessage.attr("data-type");
    enqueueSnackbar(text, {
      variant: type as "info" | "error" | "success" | "warning",
      autoHideDuration: null,
    });
  });
}
