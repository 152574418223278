import { QueryCache, QueryClient } from "@tanstack/react-query";
import { HttpError } from "../../utils/jquery_helper";
import { error as errorToast } from "../../utils/toasts";
/** @type {*} */
export const SialogicQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 2, // 2 minutes
    },
  },
  queryCache: new QueryCache({
    // global error handling ... display an error toast
    onError(error) {
      if (error instanceof HttpError) {
        if ((error as HttpError).request?.status == 401) {
          void errorToast(I18n.t("frontend.unauthorized"), error.message);
          return;
          // Handle 401 error here
        }
        // Handle 401 error here
      } else {
        void errorToast(I18n.t("frontend.error"), error.message);
      }
    },
  }),
});
